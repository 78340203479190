import MainPage from "components/the-buzz-theme/page/main";
import { ResolvedType } from "interfaces";
import {
  CategoryToAncestorsCategoryConnection,
  RootQueryToPostConnection,
} from "interfaces/wordpress";

import {
  getRecentPosts,
  getStickyPosts,
  getCategoryTabs,
  POST_COUNT,
  setPostCount,
  getStickyPostIDs,
  getFirstCategoryTabPostIds,
} from "lib/api";
import { SOCIAL_IMAGE_FALLBACK } from "lib/constants";
import { GetStaticPropsContext } from "next";
import * as React from "react";
import { NextSeo } from "next-seo";
import { getLocale } from "lib/localization";
import { FontsCircular } from "lib/scripts";
import { SupportedLanguage } from "lib/lexemes/supported-languages";

export type Props = {
  stickyPosts: RootQueryToPostConnection;
  categoryTabs: CategoryToAncestorsCategoryConnection;
  recentPosts: ResolvedType<typeof getRecentPosts>;
  recentPostsTotal?: number;
};

export default function IndexPage(props: Props) {
  return (
    <>
      <NextSeo
        title={"Bumble Buzz"}
        openGraph={{
          title: "Bumble Buzz",
          images: [
            {
              url: SOCIAL_IMAGE_FALLBACK,
            },
          ],
        }}
      />
      <FontsCircular />

      <MainPage
        stickyPosts={props.stickyPosts}
        categoryTabs={props.categoryTabs}
        recentPosts={props.recentPosts}
        recentPostsTotal={props.recentPostsTotal}
      />
    </>
  );
}

export const getStaticProps = async function getStaticProps({
  params,
  preview = false,
}: GetStaticPropsContext<{ lang: SupportedLanguage }>) {
  const lang = getLocale(undefined, params);

  const [stickyPostIDs, firstCategoryTabPostIDs] = await Promise.all([
    getStickyPostIDs(),
    getFirstCategoryTabPostIds(),
  ]);

  const excludedPostIds = [stickyPostIDs, firstCategoryTabPostIDs].flat() || [];

  const [{ stickyPosts }, { categoryTabs }, recentPosts] = await Promise.all([
    getStickyPosts(),
    getCategoryTabs({ notIn: stickyPostIDs }),
    getRecentPosts(POST_COUNT, null, null, null, excludedPostIds),
  ]);

  return {
    props: {
      lang,
      preview,
      stickyPosts,
      categoryTabs,
      recentPosts,
      recentPostsTotal: setPostCount(recentPosts.total),
    },
    revalidate: 20,
  };
};
