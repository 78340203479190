/**
 * Menu_order is controlled in CMS via wp-config.php function `graphql_post_object_connection_query_args`
 */
export const getStickyPostsScheme = `
  query GET_STICKY_POSTS {
    posts(where: {onlySticky: true}, first: 5) {
      nodes {
        id
        title
        slug
        featuredImage {
          node {
            altText
            mediaItemUrl
            mediaDetails {
              height
              width
              sizes {
                height
                sourceUrl
                width
              }
            }
          }
        }
        tags {
          nodes {
            name
            slug
          }
        }
      }
    }
  }
`;

/**
 * 4 posts for Desktop, 5 for mobile
 */
export const getCategoryTabsScheme = `
  query GET_CATEGORY_TABS (
    $notIn: [ID]
  ) {
    categories {
      edges {
        node {
          categoryPageFields {
            categoryColor
          }
          name
          slug
          posts(first: 5, where: {notIn: $notIn, orderby: {field: MENU_ORDER, order: ASC}}) {
            nodes {
              id
              title
              slug
              featuredImage {
                node {
                  altText
                  mediaItemUrl
                  mediaDetails {
                    height
                    width
                    sizes {
                      height
                      sourceUrl
                      width
                    }
                  }
                }
              }
              tags {
                nodes {
                  name
                  slug
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * Use getRecentPostsScheme from Schemes file
 */

/**
 * Collect ids from Sticky Posts
 */
export const getStickyPostIDsScheme = `
query GET_STICKY_POSTS_ID {
  posts(where: {onlySticky: true}, first: 5) {
    nodes {
      id
    }
  }
}
`;

export const getFirstCategoryTabPostIDsScheme = `
query GET_FIRST_CATEGORY_TAB_POST_IDS {
  categories(first: 1) {
    edges {
      node {
        posts(first: 5, where: {orderby: {field: MENU_ORDER, order: ASC}}) {
          nodes {
            id
          }
        }
      }
    }
  }
}
`;
