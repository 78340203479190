export const sliderOptions = {
  desktop: {
    autoplay: {
      delay: 8000,
    },
    pauseOnMouseEnter: true,
    reverseDirection: true,
    preventInteractionOnTransition: false,
    rewind: false,
    loop: true,
    overflow: "hidden",
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      dynamicBullets: false,
      clickable: true,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    a11y: {
      slideLabelMessage: "Article {{index}} of {{slidesLength}}",
    },
  },
  mobile: {
    // Default parameters
    effect: "cards",
    cardsEffect: {
      slideShadows: false,
      perSlideRotate: 0,
      perSlideOffset: 30,
    },
    preventClicks: true,
    preventClicksPropagation: false,
    centeredSlides: true,
    slideShadows: true,
    longSwipesRatio: 0.25,
    longSwipes: true,
    edgeSwipeDetection: true,
    watchSlidesVisibility: true,
    rewind: false,
    loop: true,
    loopedSlides: 2,
    slidesPerView: 1.05,
    grabCursor: true,
    pagination: false,
    slideToClickedSlide: true,
    loopFillGroupWithBlank: true,
    keyboard: {
      enabled: true,
    },
    a11y: {
      slideLabelMessage: "Article {{index}} of {{slidesLength}}",
    },
    overflow: "hidden",
    // Responsive breakpoints
    breakpoints: {
      // when window width is >= 456px
      476: {
        cardsEffect: {
          perSlideRotate: 3,
          perSlideOffset: 4,
        },
      },
    },
  },
};
