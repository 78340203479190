import { postsCategoriesForMainPage } from "lib/constants";

const SPLASH_POST_TAG = "main-page-hero-block-post";
const LOCAL_POST_TAG = "local-post";
const FEATURED_POST_TAG = "main-page-featured-post";

const WP_CATEGORIES_MAIN_PAGE = Object.values(postsCategoriesForMainPage);

const getCategorySchemeTemplate = (category: string, count = 3) => `
    category_${category.replace(" ", "")}: posts(first: ${count}, where: {
        categoryName: "${category}",
        taxQuery: {
            taxArray: {
                field: SLUG,
                taxonomy: TAG,
                operator: NOT_IN,
                terms: ["${FEATURED_POST_TAG}", "${LOCAL_POST_TAG}", "${SPLASH_POST_TAG}"]
            }
        }
    }) {
        edges {
            cursor
            node {
                uri
                title
                featuredImage {
                    node {
                        altText
                        sourceUrl
                        mediaDetails {
                            height
                            width
                            sizes {
                                height
                                sourceUrl
                                width
                            }
                        }
                    }
                }
            }
        }
        pageInfo {
            hasNextPage
            endCursor
        }
    }
`;

export function getCategoryScheme(category: string, count = 3) {
  return getCategorySchemeTemplate(category, count);
}

const mainPageCategories = WP_CATEGORIES_MAIN_PAGE.map((category) =>
  getCategoryScheme(category, 3),
).join("\n");

export const getPostsForHomeScheme = `
    query PostsForHome {
        splashPost: posts(first: 1, where: {
            orderby: {
                field: DATE,
                order: DESC
            },
            tagSlugIn: ["${SPLASH_POST_TAG}"],
            taxQuery: {
                taxArray: {
                    field: SLUG,
                    taxonomy: TAG,
                    operator: NOT_IN,
                    terms: "${LOCAL_POST_TAG}"
                }
            }
        }) {
            edges {
                node {
                    title
                    uri
                    featuredImage {
                        node {
                            altText
                            sourceUrl
                            mediaDetails {
                                height
                                width
                                sizes {
                                    height
                                    sourceUrl
                                    width
                                }
                            }
                        }
                    }
                    categories(first: 1) {
                        edges {
                            node {
                                name
                            }
                        }
                    }
                }
            }
        }
        featuredPosts: posts(first: 3, where: {
            orderby: {
                field: DATE,
                order: DESC
            },
            tagSlugIn: ["${FEATURED_POST_TAG}"],
            taxQuery: {
                taxArray: {
                    field: SLUG,
                    taxonomy: TAG,
                    operator: NOT_IN,
                    terms: ["${LOCAL_POST_TAG}", "${SPLASH_POST_TAG}"]
                }
            }
        }) {
            edges {
                node {
                    title
                    uri
                    featuredImage {
                        node {
                            altText
                            sourceUrl
                            mediaDetails {
                                height
                                width
                                sizes {
                                    height
                                    sourceUrl
                                    width
                                }
                            }
                        }
                    }
                    categories(first: 1) {
                        edges {
                            node {
                                name
                            }
                        }
                    }
                }
            }
        }
        ${mainPageCategories}
    }
`;

export const getIndexPageQueryScheme = `
    query getIndexPageByUrl {
        nodeByUri(uri: "index") {
            ... on Page {
                indexPageFields {
                    pagetitle
                }
            }
        }
    }
`;

export const getSettingsForHomePageScheme = `
    query SplashScreenLayoutForHomePage {
        settingsMainpageSplashcreenType
    }
`;

export const getInstagramFeedScheme = `
    query getInstagramFeed(
        $id: String,
    ) {
        instagramFeed(userID: $id)
    }
`;

/**
 * Menu_order is controlled in CMS via wp-config.php function `graphql_post_object_connection_query_args`
 */
export const getRecentPostsScheme = `
    query RecentPosts(
        $first: Int,
        $after: String,
        $tagSlugNotIn: [String],
        $categoryName: String,
        $tag: String,
        $notIn: [ID]
    ) {
        posts(
            first: $first
            after: $after
            where: {
                notIn: $notIn
                categoryName: $categoryName, 
                tag: $tag,
                orderby:{
                    field: MENU_ORDER,
                    order: ASC
                },
                taxQuery: {
                    taxArray: {
                        field: SLUG,
                        taxonomy: TAG,
                        operator: NOT_IN,
                        terms: $tagSlugNotIn
                    }
                }
            }
        ) {
            edges {
                cursor
                node {
                    slug
                    title
                    uri
                    featuredImage {
                        node {
                            altText
                            sourceUrl
                            mediaItemUrl
                            mediaDetails {
                                height
                                width
                                sizes {
                                    height
                                    sourceUrl
                                    width
                                }
                            }
                        }
                    }
                    categories(first: 1) {
                        edges {
                            node {
                                name
                            }
                        }
                    }
                    tags {
                        nodes {
                            name
                            slug
                        }
                    }
                }
            }
            pageInfo {
                endCursor
                hasNextPage
                total
            }
        }
    }
`;

export const getRecentCategoryPostsScheme = `
    query RecentCategoryPosts(
        $categoryName: [String]
        $first: Int
        $after: String
        $tagSlugNotIn: [String]
        $notIn: [ID]
    ) {
        categories(where: { name: $categoryName }) {
            edges {
                node {
                    name
                    posts(
                        first: $first
                        after: $after
                        where: {
                            notIn: $notIn
                            orderby:{ field: MENU_ORDER, order: ASC },
                            taxQuery: {
                                taxArray: {
                                    field: SLUG,
                                    taxonomy: TAG,
                                    operator: NOT_IN,
                                    terms: $tagSlugNotIn
                                }
                            }
                        }
                    ) {
                        edges {
                            cursor
                            node {
                                title
                                uri
                                slug
                                featuredImage {
                                    node {
                                        altText
                                        sourceUrl
                                        mediaItemUrl
                                        mediaDetails {
                                            height
                                            width
                                            sizes {
                                                height
                                                sourceUrl
                                                width
                                            }
                                        }
                                    }
                                }
                                categories(first: 1) {
                                    edges {
                                        node {
                                            name
                                        }
                                    }
                                }
                                tags {
                                    nodes {
                                        name
                                        slug
                                    }
                                }
                            }
                        }
                        pageInfo {
                            endCursor
                            hasNextPage
                            total
                        }
                    }
                }
            }
        }
    }
`;

export const getPostsForSitemapScheme = `
    query SitemapPosts {
        posts(first: 100000, where: { orderby: { field: DATE, order: DESC } }) {
            edges {
                node {
                    uri
                    modifiedGmt
                }
            }
        }
    }
`;

export const getFeaturedPostsForCategoryScheme = (
  categoryHeroBlockPostTag: string,
  categoryFeaturedPostTag: string,
) => `
    query AllPostsForCategory(
        $categoryName: [String]
    ) {
        splashPost: categories(where: { name: $categoryName }) {
            edges {
                node {
                    categoryPageFields {
                        pageTitle
                    }
                    posts(first: 1, where: {
                        tagSlugIn: ["${categoryHeroBlockPostTag}"],
                        taxQuery: {
                            taxArray: {
                                field: SLUG,
                                taxonomy: TAG,
                                operator: NOT_IN,
                                terms: ["${LOCAL_POST_TAG}"]
                            }
                        }
                    }) {
                        edges {
                            node {
                                uri
                                title
                                featuredImage {
                                    node {
                                        altText
                                        sourceUrl
                                        mediaDetails {
                                            height
                                            width
                                            sizes {
                                                height
                                                sourceUrl
                                                width
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        featuredPosts: categories(where: { name: $categoryName }) {
            edges {
                node {
                    posts(first: 4, where: {
                        tagSlugIn: ["${categoryFeaturedPostTag}"],
                        taxQuery: {
                            taxArray: {
                                field: SLUG,
                                taxonomy: TAG,
                                operator: NOT_IN,
                                terms: ["${LOCAL_POST_TAG}", "${categoryHeroBlockPostTag}"]
                            }
                        }
                    }) {
                        edges {
                            node {
                                uri
                                title
                                featuredImage {
                                    node {
                                        altText
                                        sourceUrl
                                        mediaDetails {
                                            height
                                            width
                                            sizes {
                                                height
                                                sourceUrl
                                                width
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const getRelatedPostsForCategoryScheme = `
    query RelatedPostsForCategory($categoryName: [String], $postId: [ID]) {
        posts: categories(where: { name: $categoryName }) {
            edges {
                node {
                    posts(first: 3, where: { notIn: $postId }) {
                        edges {
                            node {
                                databaseId
                                uri
                                title
                                slug
                                date
                                featuredImage {
                                    node {
                                        altText
                                        sourceUrl
                                        mediaItemUrl
                                        mediaDetails {
                                            height
                                            width
                                            sizes {
                                                height
                                                sourceUrl
                                                width
                                            }
                                        }
                                    }
                                }
                                tags {
                                    nodes {
                                        name
                                        slug
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const getPostForArticlePageScheme = (idType: string) => `
    fragment AuthorFields on User {
        name
        firstName
        lastName
        avatar {
            url
        }
    }

    fragment PostFields on Post {
        title
        content
        excerpt
        slug
        link
        date
        databaseId
        featuredImage {
            node {
                altText
                sourceUrl
                mediaItemUrl
                mediaDetails {
                    height
                    width
                    sizes {
                        height
                        sourceUrl
                        width
                    }
                }
            }
        }
        author {
            node {
                ...AuthorFields
            }
        }
        categories {
            edges {
                node {
                    name
                    categoryPageFields {
                        categoryColor
                    }
                }
            }
        }
        seo {
            title
            metaDesc
            opengraphImage {
                altText
                mediaDetails {
                    height
                    width
                }
                mediaItemUrl
            }
        }
        tags {
            edges {
                node {
                    name
                    slug
                }
            }
        }
    }

    query Post($id: ID!) {
        post(id: $id, idType: ${idType}) {
            ...PostFields
            featuredPostFields {
                seoDescription
                seoTitle
                    relatedPosts {
                        nodes {
                            ... on Post {
                                ...PostFields
                            }
                        }
                    }
            }
        }
    }
`;
